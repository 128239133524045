import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import './index.scss';
import BreadCrumbs from '../components/common/breadcrumbs'


const ContactPage = ({ data }) => {

  const breadcrumbs = {
    'Home': '/',
    'Contact': null
  }

  return < Layout pageTitle="Contact" >
    <SEO title="Contact" />
    <div className="page page-contact">

      <div className="page-breadcrumbs notification">
        <BreadCrumbs input={breadcrumbs} />
      </div>

      <div className="contact-form-text" dangerouslySetInnerHTML={{ __html: data.nodeConfigPage.field_contact_header_text.processed }}>
      </div>

      <div className="form-item" style={{paddingTop: `60px`}}>
        <iframe style={{ width: `100%`, height: `1035px` }} title="Contact Form" src="https://docs.google.com/forms/d/e/1FAIpQLSfDoo-_LeJDKrHPEa-tdJj1BgfEGIdmHRaYcV1hP5kzd34EIg/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
    </div>
  </Layout >
}

export default ContactPage


export const query = graphql`
query {
  nodeConfigPage {
    field_contact_header_text {
      value
      format
      processed
    }
  }
}
`

